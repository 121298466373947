import React from "react"
import createTemplate from "../../create-template"

import { useComponents } from "../../components"

function Princeton({ styles, ...props }) {
  const Elements = useComponents()

  return (
    <Elements.LandingPage
      styles={styles}
      {...props}
      style={styles.forElement("page", { backgroundColor: "#ffffff" })}
    >
      <div
        className="formkit-container"
        style={styles.forElement("background")}
      >
        <div className="formkit-main">
          <Elements.Heading
            className="formkit-preheader"
            name="preheader"
            defaults={{ content: "Subtitle goes here" }}
          />
          <Elements.Heading
            className="formkit-header"
            name="header"
            defaults={{ content: "Enter a catchy page headline" }}
          />
          <Elements.Content
            name="intro"
            defaults={{
              content: "Provide some more detail in this subtitle"
            }}
          />
          <Elements.Region name="precontent" className="formkit-precontent">
            <Elements.Content
              defaults={{
                content: "Provide some more detail in this content area"
              }}
            />
          </Elements.Region>
          <Elements.Heading
            className="formkit-subheader"
            name="subheader"
            tag={"h2"}
            defaults={{ content: "Here is what you'll learn:" }}
          />
          <Elements.Region name="content" className="formkit-content">
            <Elements.Content
              defaults={{
                content: "Provide some more detail in this content area"
              }}
            />
          </Elements.Region>
          <div className="formkit-testimonial">
            <div className="formkit-testimonial-mark">
              <img
                src="//pages.convertkit.com/assets/author/quote.png"
                alt=""
              />
            </div>
            <div>
              <Elements.Heading
                name="testimonial_quote"
                defaults={{
                  content:
                    "A short book testimonial goes here , lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse placerat, velit eget ornare iaculis, sem diam dapibus ante”"
                }}
              />
              <Elements.Heading
                name="testimonial_byline"
                defaults={{ content: "First Last Name, website.com" }}
              />
            </div>
          </div>
          <Elements.BuiltWith background="background" />
        </div>
        <div className="formkit-sidebar">
          <Elements.Image
            className="formkit-avatar"
            name="image"
            size={{ w: 360, h: 500 }}
          />
          <div className="formkit-card" style={styles.forElement("card")}>
            <div className="formkit-card-header">
              <Elements.Image
                className="formkit-book"
                name="book"
                size={{ w: 200, h: 300 }}
              />
              <div>
                <Elements.Heading
                  name="form_header"
                  className="formkit-form-header"
                  defaults={{
                    content: "Get a free chapter of my newest book."
                  }}
                />
                <Elements.Content
                  name="form_content"
                  className="formkit-form-content"
                  defaults={{
                    content:
                      "Enter your email address and download a free 12-pages PDF."
                  }}
                />
              </div>
            </div>

            <Elements.Form
              style={styles.forElement("form", {
                backgroundColor: "#ffffff"
              })}
            >
              <Elements.Errors />
              <Elements.CustomFields>
                <Elements.AddFieldButton />
                <Elements.Button
                  name="submit"
                  group="button"
                  defaults={{ content: "Get a free ebook" }}
                />
              </Elements.CustomFields>
            </Elements.Form>
            <Elements.Content
              name="disclaimer"
              defaults={{
                content: "We respect your privacy. Unsubscribe at any time."
              }}
            />
          </div>
        </div>
      </div>
    </Elements.LandingPage>
  )
}

Princeton.style = "landing_page"
Princeton.categories = ["eBook", "Profile"]
Princeton.thumbnail = ""
Princeton.preview = "https://demo.ck.page/princeton"
Princeton.fields = [
  {
    name: "first_name",
    label: "Your first name"
  },
  {
    name: "email_address",
    label: "Your email address"
  }
]

export default createTemplate(Princeton, { name: "Princeton" })
